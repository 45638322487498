import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Button } from 'react-bootstrap';
import { TOGCharacter } from '../../../modules/tog/common/components/tog-character';

const TOGGuidesEarlyPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="tog">
      <ul className="breadcrumb">
        <li>
          <Link to="/tog/">Tower of God: New World</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/tog/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Wishlist tips</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/tog/categories/category_wishlist.png"
            alt="Wishlist tips"
          />
        </div>
        <div className="page-details">
          <h1>Wishlist tips</h1>
          <h2>A guide for the Wishlist feature and how to fill it.</h2>
          <p>
            Last updated: <strong>18/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Wishlist tips" />
        <StaticImage
          src="../../../images/tog/guides/wishlist.webp"
          alt="Image"
        />
        <p>
          The Wishlist system in Tower of God: New World allows you to pick{' '}
          <strong>
            5 SSR characters and you have a higher chance of pulling them.
          </strong>{' '}
          All Colors are available here, including Yellow and Purple (they
          aren't treated like typical Light/Dark from other games).
        </p>
        <p>Here's how your choice will affect your pulls:</p>
        <ul>
          <li>
            The character you place in the first spot from the left will get the
            biggest boost and the chance to pull them will be increased to{' '}
            <strong>1.8%</strong>,
          </li>
          <li>
            The 4 other slots will boost the rates of characters placed there to
            <strong> 0.45% each</strong> (so 1.8% total),
          </li>
          <li>
            The remaining percentage (1.1%) will be split between the SSR not on
            the Wishlist and the SSR+ characters.
          </li>
        </ul>
        <p>
          This means that picking the right characters to be added to your
          Wishlist is very important.
        </p>
        <SectionHeader title="SSR Characters" />
        <p>
          When it comes to SSR characters, you have to abuse the 'left slot'
          system to target the characters you want to pull more and the section
          below shows you the priority for the units to place there.
        </p>
        <h5>Data Tower Units - Currently Top Priority</h5>
        <div className="employee-container for-nikke with-float">
          <TOGCharacter
            mode="icon"
            slug="khun-aguero-ice-spear"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="anaak-spear-bearer"
            enablePopover
            showIcon
          />
        </div>
        <h5>Priority 1</h5>
        <div className="employee-container for-nikke with-float">
          <TOGCharacter
            mode="icon"
            slug="ha-yura-idol"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="black-march-bam"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="anaak-princess-of-zahard"
            enablePopover
            showIcon
          />
        </div>
        <h5>Priority 2</h5>
        <div className="employee-container for-nikke with-float">
          <TOGCharacter
            mode="icon"
            slug="anima-narae-seonwoo"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="strategist-khun-aguero"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="center-director-blanc"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="daughter-slayer-vivi"
            enablePopover
            showIcon
          />
        </div>
        <h5>Priority 3</h5>
        <div className="employee-container for-nikke with-float">
          <TOGCharacter
            mode="icon"
            slug="anna-soul-guardian"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="tactician-lero-ro"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="hatz-donghae"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="reflejo-dark-shadow"
            enablePopover
            showIcon
          />
        </div>
        <h5>Priority 4</h5>
        <div className="employee-container for-nikke with-float">
          <TOGCharacter
            mode="icon"
            slug="endorsi-shinsu-of-the-heart"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="crimson-soul-novick"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="forever-prepping-wangnan-ja"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="varagarv-mad-dog"
            enablePopover
            showIcon
          />
        </div>
        <h5>Priority 5</h5>
        <div className="employee-container for-nikke with-float">
          <TOGCharacter
            mode="icon"
            slug="musician-yellowy"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="child-endorsi"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="maniacal-xia-xia"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="destined-path-hwaryun"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="lero-ro-regular"
            enablePopover
            showIcon
          />
        </div>
        <SectionHeader title="SSR+ Characters" />
        <p>
          As for SSR+ characters, focus first section, so grab 1 copy of those
          characters and then gather dupes in the order of priority.
        </p>
        <h5>Must have at least one copy</h5>
        <div className="employee-container for-nikke with-float">
          <TOGCharacter
            mode="icon"
            slug="fast-ship-evan"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="data-khun-mascheny"
            enablePopover
            showIcon
          />
          <TOGCharacter mode="icon" slug="data-zahard" enablePopover showIcon />
          <TOGCharacter
            mode="icon"
            slug="steel-armored-karaka"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="albelda-soul-fragment"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="yihwa-yeon-healing-flame"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="yirang-yeon-great-family"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="garam-hermit"
            enablePopover
            showIcon
          />
        </div>
        <h5>Dupe Priority 1</h5>
        <div className="employee-container for-nikke with-float">
          <TOGCharacter
            mode="icon"
            slug="white-torn-authority"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="kaiser-ruler"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="dowon-forget-me-not"
            enablePopover
            showIcon
          />
        </div>
        <h5>Dupe Priority 2</h5>
        <div className="employee-container for-nikke with-float">
          <TOGCharacter
            mode="icon"
            slug="albelda-soul-fragment"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="yihwa-yeon-healing-flame"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="data-khun-edahn"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="twenty-fifth-bam-revolution"
            enablePopover
            showIcon
          />
        </div>
        <h5>Dupe Priority 3</h5>
        <div className="employee-container for-nikke with-float ">
          <TOGCharacter
            mode="icon"
            slug="garam-hermit"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="lightning-spear-khun-mascheny"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="data-khun-mascheny"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="steel-armored-karaka"
            enablePopover
            showIcon
          />
        </div>
        <h5>Dupe Priority 4</h5>
        <div className="employee-container for-nikke with-float">
          <TOGCharacter
            mode="icon"
            slug="fast-ship-evan"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="yirang-yeon-great-family"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="kallavan-essence-of-nothingness"
            enablePopover
            showIcon
          />
          <TOGCharacter mode="icon" slug="thorn-viole" enablePopover showIcon />
        </div>
        <h5>Dupe Priority 5</h5>
        <div className="employee-container for-nikke with-float">
          <TOGCharacter
            mode="icon"
            slug="data-gustang"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="ha-yuri-regular"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="rak-awakened"
            enablePopover
            showIcon
          />
          <TOGCharacter
            mode="icon"
            slug="ha-yuri-kranos"
            enablePopover
            showIcon
          />
        </div>
        <SectionHeader title="Conowen's Sheet" />
        <p>For the most up to date Wishlist tips, check this sheet:</p>
        <Button
          variant="primary"
          href="https://docs.google.com/spreadsheets/u/0/d/1paOxiwoKOzzR0E5W_r1SYLVuliJomnz8BkNQt8vNhQU/htmlview#"
          target="_blank"
          rel="noreferrer"
        >
          Conowen's ToG Sheet
        </Button>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default TOGGuidesEarlyPage;

export const Head: React.FC = () => (
  <Seo
    title="Wishlist tips | Tower of God: New World | Prydwen Institute"
    description="A guide for the Wishlist feature and how to fill it."
    game="tog"
  />
);
